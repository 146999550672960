<template>
  <div :class="['px-2 w-full', { 'flex items-center h-full': !showFull }]">
    <div
      v-if="labels?.length"
      ref="labelContainer"
      :class="['flex items-center flex-1 w-full overflow-hidden gap-1', showFull? 'flex-wrap': 'flex-nowrap']"
    >
      <div
        v-for="label in visibleLabels"
        :key="label.id"
        class="px-2 min-h-6 rounded-md py-1"
        :style="getLabelStyle(label.color as T)"
      >
        <Tooltip :text="label.name" class="flex items-center">
          <template #default="{ getTextRef }">
            <span :ref="getTextRef" :class="['line-clamp-1 text-xs leading-4 max-h-4 flex items-start', showFull? 'whitespace-nowrap' :'break-all']">{{ label.name }}</span>
          </template>
        </Tooltip>
      </div>
      <UTooltip
        v-if="hiddenLabels.length > 0"
        :popper="{ placement: 'top', arrow: true }"
        :prevent="hiddenLabels.length === 0"
        :ui="{ base: 'h-fit', background: 'bg-white', container: 'max-w-96' }"
      >
        <template #text>
          <div>
            <div v-for="label in hiddenLabels" :key="label.id" class="text-xs leading-4 truncate">
              {{ label?.name }}
            </div>
          </div>
        </template>
        <div
          ref="hiddenLabelsCounter"
          class="text-xs text-gray-700 px-1.5 py-0.5 bg-gray-200 rounded-md"
        >
          +{{ hiddenLabelsCount }}
        </div>
      </UTooltip>
    </div>
    <div v-else class="py-1 rounded-md text-xs group-hover/cell:visible invisible">-</div>
  </div>
</template>

<script lang="ts" setup generic="T extends string">
import type { StyleValue } from 'vue'
import type { FieldLabel } from '#field/types'

const props = defineProps({
  labels: {
    type: Object as PropType<Array<FieldLabel>>,
    required: true,
  },
  getLabelStyle: {
    type: Function as PropType<(color: T) => StyleValue>,
    required: true
  },
  showFull: {
    type: Boolean,
    default: false
  },
})

const labelContainer = ref<HTMLDivElement | null>(null)
const hiddenLabelsCounter = ref<HTMLDivElement | null>(null)
const visibleLabels = ref<FieldLabel[]>([])
const hiddenLabelsCount = ref(0)

const hiddenLabels = computed(() => {
  return labels.value.slice(visibleLabels.value.length)
})

const labels = computed(() => props.labels || [])

const updateVisibleLabels = async () => {
  if (!labelContainer.value) return

  const containerWidth = labelContainer.value.offsetWidth
  let currentWidth = 0

  // Calculate the width of the hidden labels counter
  const hiddenLabelsCounterWidth =
    (hiddenLabelsCounter.value ? hiddenLabelsCounter.value.offsetWidth : 0) + 16 // 16 is right space

  const newVisibleLabels = labels.value.reduce((acc: FieldLabel[], label: FieldLabel, currentIndex) => {
    const labelElement = document.createElement('div')
    labelElement.textContent = label.name || ''
    labelElement.style.visibility = 'hidden'
    labelElement.style.position = 'absolute'
    labelElement.className = 'px-2 py-1 rounded-md text-xs max-w-16'
    document.body.appendChild(labelElement)

    const labelWidth = labelElement.offsetWidth
    labelElement.remove()

    if (currentIndex === 0) {
      currentWidth += labelWidth
      return [label]
    }

    if (
      currentWidth + labelWidth + hiddenLabelsCounterWidth <=
      containerWidth
    ) {
      currentWidth += labelWidth + 4 // 4px for gap
      return [...acc, label]
    }

    return acc
  }, [])

  visibleLabels.value = newVisibleLabels
  hiddenLabelsCount.value =
    (props.labels?.length || 0) - newVisibleLabels.length
}

let resizeObserver: ResizeObserver | null = null

onMounted(() => {
  nextTick(() => {
    if (props.showFull) {
      return visibleLabels.value = props.labels
    }

    updateVisibleLabels()
    resizeObserver = new ResizeObserver(() => {
      updateVisibleLabels()
    })

    if (labelContainer.value) {
      resizeObserver.observe(labelContainer.value)
    }
  })
})

onUnmounted(() => {
  if (resizeObserver) {
    resizeObserver.disconnect()
  }
})

watch(
  () => props.labels,
  (value) => {
    if (props.showFull) {
      return visibleLabels.value = value
    }

    if (value.length) {
      nextTick(updateVisibleLabels)
      if (labelContainer.value) {
        resizeObserver?.observe(labelContainer.value)
      }
    }
  },
  { deep: true }
)
</script>
